<template>
  <div>
    <validation-observer ref="simple">
      <b-form>
        <b-card-code title=" التفاصيل">
          <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <b-row>
            <b-col md="3" xl="3">
              
                <b-form-group
                  label-for="title"
                
                >
             
                <label> الكود</label>
                <br/>
                {{ Form.code }}
              </b-form-group>
           
            </b-col>
            <b-col md="3" xl="3">
              
              <b-form-group
                label-for="title"
              
              >
           
              <label> تاريخ الصلاحية</label>
              <br/>
              {{ Form.deadline }}
            </b-form-group>
         
          </b-col>
          <b-col md="3" xl="3">
              
              <b-form-group
                label-for="title"
              
              >
           
              <label> من قبل</label>
              <br/>
              {{ Form.prepared_by }}
            </b-form-group>
         
          </b-col>
          <b-col md="3" xl="3" v-if="Form.status">
              
              <b-form-group
                label-for="title"
              
              >
           
              <label> الحالة</label>
              <br/>
              {{ Form.status.name }}
            </b-form-group>
         
          </b-col>
          <b-col md="3" xl="3">
              
              <b-form-group
                label-for="title"
              
              >
           
              <label> transaction_id </label>
              <br/>
              {{ Form.transaction_id }}
            </b-form-group>
         
          </b-col>
          <b-col md="3" xl="3">
              
              <b-form-group
                label-for="title"
              
              >
           
              <label> القسم</label>
              <br/>
              {{ Form.department_id}}
            </b-form-group>
         
          </b-col>
             <b-col md="8" xl="8">
            <b-form-group label-for="docNote">
              <label>الملاحظات</label>
              <b-form-textarea
                id="textarea-default"
                v-model="Form.notes"
                placeholder="الملاحظات"
                rows="1"
              />
            </b-form-group>
             </b-col>
          
          </b-row>
           <b-table
        ref="refServiceListTable"
        class="position-relative"
        :items="Form.details"
        responsive
        :fields="tableColumns"
        
        show-empty
        empty-text="No matching records found"


      >
    
       <template #cell(quantity)="data">
        <b-form-input
                                      v-model="data.item.quantity"
                                      type="number"
                                     
                                      class="mb-2"

                                      placeholder="الكمية "
                                    />
       </template>
 </b-table>
      
          
        </validation-observer>
        </b-card-code>
      </b-form>
      <b-row>
        <b-col md="4" xl="3">
              <b-button variant="primary" @click="update">
             
                <span class="align-middle"> حفظ وموافقة  </span>
              </b-button>
            </b-col>
          
              <b-col md="4" xl="3" class="mx-5">
              <b-button variant="danger" @click="UpdateCurrency(0)">
             
رفض              </b-button>
            </b-col>
          </b-row>
    </validation-observer>
    <!-- <b-col md="4" xl="2">
      <b-button variant="purple " @click="$router.go(-1)">
        <span class="align-middle">رجوع</span>
      </b-button>
    </b-col> -->
  </div>
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import vSelect from "vue-select";
import { required, max, is_earlier } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";

import {
  BRow,
  BCol,
  BFormGroup,
  BInputGroupPrepend,
  BInputGroup,
  BDropdown,
  BDropdownItem,
  BDropdownItemButton,
} from "bootstrap-vue";
import Cleave from "vue-cleave-component";
// eslint-disable-next-line import/no-extraneous-dependencies
import "cleave.js/dist/addons/cleave-phone.us";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import {
  BFormDatepicker,
  BFormCheckbox,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BButton,
    BTable,
  BForm,
  BFormInvalidFeedback,
} from "bootstrap-vue";
export default {


  components: {
    ToastificationContent,
    required,
    BDropdown,
    BDropdownItem,
    BDropdownItemButton,
    BFormInvalidFeedback,
    VueGoodTable,
     BTable,
    BForm,
    ValidationObserver,
    ValidationProvider,

    BCardCode,
    BButton,
    BFormInput,
    BFormFile,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BFormDatepicker,
    Cleave,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    BRow,

    BCol,
  },

  data() {
    return {
      Form:{},
      name: "",
      code:'',
      brand_name:'',
      optioneItemCategory:[],
      en_name: "",
      item_category_id:'',
      tableColumns: [
       
         { key: "item_id.name", label: "المادة"},
          { key: "item_id.code", label: "الكود"},
    { key: "unit_id.name", label: "الواحدة"},
    { key: "quantity", label: "الكمية"},

      ],
      notes:'',
      searchTerm: "",
      id: "",
   
    };
  },

  created() {
    this.getCurrency()

  },

  methods: {
  
 
    UpdateCurrency(status) {
      
      return new Promise((resolve, reject) => {

        this.$refs.simple
          .validate()
          .then((success) => {
            if (success) {
              let data ={
                notes:this.notes,
                approve:status
              }

            
             
          
      this.$http.post(`/api/v1/complete-out-transactions-need-complete/${this.$route.params.id}`,data).then((res) => {
        //console.log(res);
        
        this.$swal({
          title: "",
          text: "تم   بنجاح",
          icon: "success",
          confirmButtonText: "موافق",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
       this.$router.push('/apps/complete-out-transaction')
      });
    }})})
    },
    
        
      update() {
        return new Promise((resolve, reject) => {
          this.UpdateCurrency(1)
          this.$refs.simple
            .validate()
            .then((success) => {
              if (success) {
       
     this.Form.details=     this.Form.details.map(el => ({
        ...el,
        item_id: el.item_id.id,
        unit_id: el.unit_id.id,
    
        department_id: el.department,
      }))
        this.$http.put(`/api/v1/purchase-order/${this.$route.params.id}`, this.Form).then((res) => {
          //console.log(res);
   
          this.$swal({
            title: "",
            text: "تمت تعديل  بنجاح",
            icon: "success",
            confirmButtonText: "موافق",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
         
        });
      }})})
      },
      

    getCurrency() {
      this.$http.get(`/api/v1/purchase-order/${this.$route.params.id}`).then((res) => {
      //  console.log('rewards', res.data.data)
       this.Form=res.data.data
   
      });
    },

    //
  },
};
</script> 

<style scoped>
label {
  color: rgba(112, 192, 203, 255);
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.btn-purple {
  color: white;
  background-color: #7e7a9f;
  margin-top: 25px;
}

span {
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.align-left {
  left: 50px;
}
.but {
  top: 40px;
}
</style>